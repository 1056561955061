import { React, useState, useEffect } from "react";
import {
  Loading,
  Input,
  Upload,
  CalendarPicker,
  Button,
  Notification,
  toaster,
  Panel,
  TextArea,
  Select,
  Badge
} from "@appkit4/react-components";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import { getrequest, postrequest } from "../../services/global.service";
import * as XLSX from 'xlsx';
import { Buffer } from 'buffer';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const defaultErrors = {
  surveyNameError: false,
  surveyDescriptionError: false,
  fileError: false,
};

const UploadSurvey = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [isEdit, setIsEdit] = useState(false);

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });
  const [surveyNames, setSurveyNames] = useState([]);
  const items = [];
  const [filteredItems, setFilteredItems] = useState([items]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [surveydetails, setSurveydetails] = useState({
    expiryDate: undefined,
    surveyDescription: "",
    surveyName: "",
    surveyId: "",
  });
  const [errors, setErrors] = useState(defaultErrors);

  const [search, setSearch] = useState('');

  const [rows, setRows] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (location.state != null) {
      setIsEdit(true);
      let surveyDetailsLocation = {
        surveyName: location.state.surveyName,
        surveyDescription: location.state.surveyDescription,
        surveyId: location.state.surveyid,
        expiryDate: location.state.expiryDate
          ? new Date(location.state.expiryDate)
          : undefined,
      };
      setSurveydetails(surveyDetailsLocation);
    }
    const fetchSurveyNames = async () => {
      const names = await getAllSurveys();
      console.log(names);
      const qsNames = names.map(item => item.surveyName);
      console.log(qsNames);
      setSurveyNames(qsNames);
      setFilteredItems(qsNames);
      setIsLoading(false);
    };
    fetchSurveyNames();
  }, [location.state]);

  const getAllSurveys = async () => {
    try {
      const response = await getrequest(`${baseURL}/api/Survey/GetSurveyList`, {
        params: { teamName: JSON.parse(Cookies.get("seletedTeam")).name },
      });
      const data = JSON.parse(response.data.data);
      console.log(data);
      setRows(data);
      return data;
    }
    catch (error) {
      navigate("/service-problem");
      return [];
    };
  }

  const userinfo = JSON.parse(Cookies.get("userdetails"));

  const fileHandler = (file) => {
    setSelectedFile(file);
  };

  const cancelClick = () => {
    navigate(-1);
  }

  const validate = () => {
    setErrors((prevState) => ({
      ...prevState,
      fileError: !selectedFile ? true : false,
      surveyNameError: surveydetails.surveyName === "" ? true : false,
      surveyDescriptionError:
        surveydetails.surveyDescription === "" ? true : false,
    }));
    if (
      !selectedFile ||
      surveydetails.surveyName === "" ||
      surveydetails.surveyDescription === ""
    ) {
      return true;
    }
    return false;
  };

  // Utility function to extract UUIDs from surveyQuestionnaire JSON
  const extractUUIDsFromSurvey = (surveyQuestionnaire) => {
    let uuids = [];
    const traverse = (data) => {
      if (Array.isArray(data)) {
        data.forEach(item => traverse(item));
      } else if (typeof data === 'object' && data !== null) {
        for (const key in data) {
          if (key === 'uuid' && typeof data[key] === 'string') {
            uuids.push(data[key]);
          } else {
            traverse(data[key]);
          }
        }
      }
    };
    
    traverse(JSON.parse(surveyQuestionnaire));
    return uuids;
  };

  // Utility function to read Excel file and extract UUIDs
  const readAndExtractUUIDsFromFile = async (file) => {
    const workbook = await readExcelFile(file);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(sheet);
    const uuids = data.map(row => row.UUID).filter(uuid => uuid !== undefined);
    return uuids;
  };

  // reading the excel files
  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        resolve(workbook);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const submitSurvey = async (event) => {
    event.preventDefault();
    if (validate()) {
      displayAlert("Please upload file");
      return false;
    } else {
      const formData = new FormData();
      if (selectedFile && selectedFile.originFile) {
        formData.append("File", selectedFile.originFile);
      } else {
        console.log("No file selected");
        displayAlert("Please upload file");
        return;
      }

      formData.append("surveyName", surveydetails.surveyName);
      formData.append("surveyDescription", surveydetails.surveyDescription);
      formData.append("createdBy", userinfo.email);

      if (selectedDropdownValue) {
        const selectedSurvey = rows.find(row => row.surveyName === selectedDropdownValue);
        if (selectedSurvey && selectedSurvey.surveyid) {
          formData.append("previousSurveyID", selectedSurvey.surveyid);
        }
      }
      formData.append("teamName", JSON.parse(Cookies.get("seletedTeam")).name);

      if (isEdit) {
        formData.append("versionFlag", 1);
        formData.append("expiryDate", surveydetails.expiryDate);
      } else {
        formData.append("versionFlag", 0);
        formData.append(
          "expiryDate",
          surveydetails.expiryDate
            ? moment(surveydetails.expiryDate).format("DD-MM-YYYY")
            : ""
        );
      }

      console.log("Selected Dropdown value before comparison : ", selectedDropdownValue);

      if (selectedDropdownValue) {
        const selectedSurvey = rows.find(row => row.surveyName === selectedDropdownValue);
        console.log("selected survey ", selectedSurvey);

        if (selectedSurvey) {
          const surveyQuestionnaire = selectedSurvey.surveyQuestionnaire;
          if (!surveyQuestionnaire) {
            console.error("No surveyQuestionnaire found for the selected survey.");
            displayAlert("Selected survey does not contain valid questionnaire data. Please select a different survey.");
            return;
          }

          // Extract UUIDs from surveyQuestionnaire
          const oldUUIDs = extractUUIDsFromSurvey(surveyQuestionnaire);
          console.log("UUIDs in surveyQuestionnaire: ", oldUUIDs);

          // Read the new file and extract UUIDs
          const newFileUUIDs = await readAndExtractUUIDsFromFile(selectedFile.originFile);
          console.log("UUIDs in new file: ", newFileUUIDs);

          // Compare the UUIDs
          const addedUUIDs = newFileUUIDs.filter(uuid => !oldUUIDs.includes(uuid));
          const removedUUIDs = oldUUIDs.filter(uuid => !newFileUUIDs.includes(uuid));

          console.log("Added UUIDs: ", addedUUIDs);
          console.log("Removed UUIDs: ", removedUUIDs);

          // Modified logic to allow adding UUIDs but disallow removing any existing UUIDs
          const isModified = removedUUIDs.length > 0 && addedUUIDs.length === 0;
          const hasAdditions = addedUUIDs.length > 0;

          if (isModified) {
            displayAlert("Past data cannot be changed");
            return;
          } else if (hasAdditions) {
            console.log("Additional UUIDs found");
          } else {
            console.log("No changes found");
          }
        } else {
          console.log("No matching survey found");
          return;
        }
      }

      const URL = `${baseURL}/api/Survey/AddSurvey?teamName=${
        JSON.parse(Cookies.get("seletedTeam")).name
      }`;
      console.log("Now printing formData");
      console.log(formData);

      try {
        const response = await postrequest(URL, formData);
        displayAlert(response.data.message);
        cancelClick();
      } catch (error) {
        console.log("error");
        console.log(error);
        navigate("/service-problem");
      }
    }
  };

  const handleInputChange = (value, e) => {
    const { name } = e.target;
    setSurveydetails({
      ...surveydetails,
      [name]: value,
    });
  };

  const handleDropdownChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedDropdownValue(selectedOption);
      console.log("selectedOption  ", selectedOption);
    } else {
      setSelectedDropdownValue(null);
    }
  };

  const displayAlert = (text) => {
    return toaster.notify(
      <Notification title="Alert" message={text} status="" closeable />,
      { position: "topCenter", duration: 20000 }
    );
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      <Button onClick={cancelClick} kind="text" className="back-button">
        Back
      </Button>

      <Panel title="Enter Questionnaire Details">
        <Input
          required
          id="surveyName"
          title="Questionnaire Name"
          name="surveyName"
          value={surveydetails.surveyName}
          onChange={handleInputChange}
          className="mb-md-3"
          error={errors.surveyNameError}
          errorNode={
            errors.surveyNameError && (
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a survey name
              </div>
            )
          }
        />

        <TextArea
          required
          id="surveyDescription"
          title="Questionnaire Description"
          name="surveyDescription"
          value={surveydetails.surveyDescription}
          onChange={handleInputChange}
          className="mb-md-3"
        />

        {errors.surveyDescriptionError && (
          <div
            id="errormessage"
            aria-live="polite"
            className="ap-field-email-validation-error"
          >
            Please enter a survey description
          </div>
        )}

        <Upload
          onChange={fileHandler}
          multiple={false}
          autoUpload={false}
          acceptFileType=".XLSX"
          uploadInstruction="You can upload Excel files. The max file size is 10mb."
          uploadTitle="Upload your file"
          maxFileSize={10 * 1024 * 1024}
          config={{
            trigger: false,
            type: "inline",
            size: true,
          }}
        />
        {errors.fileError && (
          <div className="ap-field-email-validation-error">
            Please upload a file
          </div>
        )}
        <div className='qDropdown'>
          <p className='qDropdown1'>Select Previous Questionnaire (optional)</p>
          <Select className='qDropdown2' searchable data={filteredItems.map(name => ({ label: name, value: name }))}
            onSelect={(selectedOption) => handleDropdownChange(selectedOption)}></Select>
        </div>

        <CalendarPicker
          placeholder="DD/MM/YYYY"
          locale="en"
          fieldTitle="Expiry date"
          minDate={
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() + 1
            )
          }
          onChange={(date) =>
            setSurveydetails({
              ...surveydetails,
              expiryDate: date,
            })
          }
          format="DD/MM/YYYY"
          value={surveydetails.expiryDate}
          disabled={isEdit}
          className="mb-md-3"
        />

        <Button kind="primary" type="submit" onClick={submitSurvey}>
          Save
        </Button>
      </Panel>
    </div>
  );
}

export default UploadSurvey;
