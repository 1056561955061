import { React, useState, useEffect } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import surveypage from "../../assets/images/SurveyLanding.png";
import { uploadBlob } from "../../services/blob-storage.service";
import { getrequest, postrequest } from "../../services/global.service";
import {
  CalendarPicker,
  InputNumber,
  Select,
  Tooltip,
  TextArea,
  Loading,
  Button,
  Notification,
  toaster,
  Tab,
  Tabs,
  Panel,
  Radio,
  Modal,
  Badge,
  Upload
} from "@appkit4/react-components";

const SurveyForm = () => {
  const [questinnaireJSON, setquestinnaireJSON] = useState([]);
  const [surveyVersion, setSurveyVersion] = useState("");
  const [data, setData] = useState({});
  const [surveyResponseId, setSurveyResponseId] = useState("");
  const [resSurveyName, setResSurveyName] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startSurvey, setStartSurvey] = useState(false);
  const [activeIndex, setactiveIndex] = useState(0);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [previousSurveyID, setPreviousSurveyID] = useState(null);

  let location = useLocation();
  let navigate = useNavigate();
  const { surveyId } = useParams();

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });

  var saveSurveyResponseURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/SaveSurveyResponse`;
  var getSurveyDetailsURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/GetSurvey`;
  var getSurveyResponseURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/GetSurveyResponse`;

  useEffect(() => {
    setIsLoading(true);
    getSurveyDetails();
  }, []);

  const getSurveyDetails = async () => {
    await getrequest(getSurveyDetailsURL, {
      params: {
        surveyId: surveyId,
        surveyUserEmail: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail,
      },
    })
      .then((response) => {
        console.log("Previous Survey Response: ", response.data.previousSurveyResponse);
  
        if (!response || !response.data) {
          console.error("No response data received from survey details.");
          displayAlert("Survey details not found. Please try again later.");
          navigate("/service-problem");
          return;
        }
  
        if (process.env.NODE_ENV === "development" || response.data.result) {
          let data = response.data.data;
          if (!data) {
            console.error("No data returned from survey details response.");
            displayAlert("Survey details not found. Please try again later.");
            navigate("/service-problem");
            return;
          }
  
          let arr = JSON.parse(data);
          console.log("TEST", arr);
  
          if (arr.previousSurveyID && arr.previousSurveyResponse) {
            const previousResponse = JSON.parse(arr.previousSurveyResponse);
            setData((prevState) => ({ ...prevState, ...previousResponse }));
            console.log("Previous Survey Response: ", previousResponse);
          } else {
            console.log("No previousSurveyID or previousSurveyResponse available.");
          }
  
          setIsSubmitted(arr.isSubmit);
          setIsActive(arr.isActive);
          setIsExpired(arr.isExpired);
          setSurveyDescription(arr.surveyDescription.split("\n"));
  
          if (!arr.surveyQuestionnaire) {
            console.error("No surveyQuestionnaire found in response data.");
            displayAlert("Survey details are incomplete. Please try again later.");
            navigate("/service-problem");
            return;
          }
  
          setquestinnaireJSON(JSON.parse(arr.surveyQuestionnaire).components[0].components);
          setSurveyVersion(arr.version);
          setResSurveyName(arr.surveyName);
  
          if (arr.previousSurveyID) {
            setPreviousSurveyID(arr.previousSurveyID);
            getPreviousSurveyResponse(arr.previousSurveyID);
          } else {
            setIsLoading(false);
          }
  
          if (arr.surveyResponse && arr.surveyResponse !== "") {
            setData(JSON.parse(arr.surveyResponse));
          }
          if (arr.surveyResponseId != null) setSurveyResponseId(arr.surveyResponseId);
        } else {
          navigate("/unauthorised");
        }
      })
      .catch((error) => {
        console.error("Error fetching survey details:", error);
        console.error("Error details:", error.response?.data || error.message);
        displayAlert("Error fetching survey details. Please try again later.");
        setIsLoading(false);
      });
  };
  
  const getPreviousSurveyResponse = async (previousSurveyID) => {
    try {
      const response = await getrequest(getSurveyResponseURL, {
        params: { surveyResponseId: previousSurveyID },
      });
      console.log("Full previous survey response: ", response);
  
      if (response && response.data && response.data.data) {
        const surveyResponseString = response.data.data.surveyResponse;
  
        if (
          surveyResponseString &&
          surveyResponseString !== '""' &&
          typeof surveyResponseString === "string"
        ) {
          try {
            const previousData = JSON.parse(surveyResponseString);
            console.log("Parsed previous survey response data: ", previousData);
  
            if (typeof previousData === "object" && previousData !== null) {
              setData((prevState) => ({ ...prevState, ...previousData }));
            } else {
              console.error("Parsed survey response is not an object:", previousData);
            }
          } catch (e) {
            console.error("Error parsing survey response data: ", e);
          }
        } else {
          console.warn("Survey response is empty or invalid.");
        }
      } else {
        console.error("Previous survey response is missing or invalid.");
      }
    } catch (error) {
      console.error("Error fetching previous survey response:", error);
      displayAlert("Error fetching previous survey response. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };
  
  const evalCustomConditional = (cc, data) => {
    if (!cc) return false;

    const eqSymbolLocation = cc.indexOf("=") + 1;
    let expr = cc.substring(eqSymbolLocation).trim();
    expr = expr?.replace(/\.includes/g, "?.includes");
    expr = expr?.replace(/\)data/g, ") && data");

    return executeExpression(expr, data);
  };

  const executeExpression = (expr, data) => {
    try {
      return !!eval(expr);
    } catch (error) {
      return false;
    }
  };

  const getComponentRequiredComponents = (c) => {
    if (!c) return null;

    if (c.type === "datagrid") return getDatagridRequiredComponents(c);

    if (
      c.validate?.required === true &&
      (!c.customConditional || evalCustomConditional(c.customConditional))
    ) {
      return c;
    }

    return null;
  };

  const getDatagridRequiredComponents = (dtGrd) => {
    if (!dtGrd || dtGrd.type !== "datagrid") return null;

    const rows = (dtGrd.defaultValue[0] || []).map((h) =>
      h?.replace(/\s/g, "")
    );
    const tbl = dtGrd.components[0];
    let headerArr = tbl.rows[0];

    let out = [];
    for (let cell of headerArr) {
      let header = cell.components[0];
      if (header.validate.required) {
        out = out.concat(
          rows.map((rH) => ({
            key: `${header.key}_${rH}`,
          }))
        );
      }
    }
    return out;
  };

  const validateForm = () => {
    const requiredSections = questinnaireJSON.map((q) =>
      q.components.map((d) => getComponentRequiredComponents(d))
    );

    const requiredKeys = requiredSections
      .flat(2)
      .filter((v) => v)
      .map((t) => t.key);

    const completedComponents = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== undefined)
    );
    const completedKeys = Object.keys(completedComponents);

    const result = requiredKeys.filter(
      (rK) => !completedKeys.some((cK) => cK === rK)
    );

    setErrors(result);
    if (result.length) {
      return true;
    }
  };

  const submitForm = async (type) => {
    if (type === "submit") {
      setSubmitLoading(true);
      if (validateForm()) {
        setSubmitLoading(false);
        setShowSubmitModal(false);
        return false;
      }
    }
  
    try {
      const updatedData = { ...data }; 
      const fileUploadPromises = Object.entries(updatedData).map(async ([key, value]) => {
        if (value?.originFile instanceof File) {
          const uploadedUrl = await uploadBlob(value.originFile);
          if (!uploadedUrl) throw new Error("File upload failed");
          updatedData[key] = uploadedUrl;
        }
      });

      await Promise.all(fileUploadPromises);
  
      let payloadData = {
        surveyId: surveyId,
        surveyResponseId: surveyResponseId,
        surveyName: resSurveyName,
        surveyUserEmail: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail,
        surveyResponse: JSON.stringify(updatedData),
        version: surveyVersion,
        isSubmit: type === "save" ? false : true,
      };

      const response = await postrequest(saveSurveyResponseURL, payloadData);
      if (response.data.result) {
        if (type === "save") {
          displayAlert(type);
        } else {
          navigate("/form-submitted");
        }
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      displayAlert("Error submitting form. Please try again later.");
      setSubmitLoading(false);
    } finally {
      setSubmitLoading(false);
    }
  };
  
  const displayAlert = (text) => {
    return toaster.notify(
      <Notification
        title="Alert"
        message={text}
        status=""
        closeable
      />,
      { position: "topCenter", duration: 5000 }
    );
  };

  const handleChange = (value, e) => {
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleNumberChange = (value, _formattedValue, e) => {
    setData({
      ...data,
      [e.target.name]: value
        .toString()
        .split(".")
        .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
        .join("."),
    });
  };

  const allTooltip = (text, customClass) =>
    (text !== "" || text === undefined) && (
      <Tooltip
        trigger="hover"
        position="top-left"
        distance={4}
        appendAfterTarget={true}
        content={text}
      >
        <button
          data-tooltip="true"
          tabIndex={0}
          aria-label="tooltip"
          className={`Appkit4-icon icon-information-outline ap-field-icon-btn mb-md-3 ${customClass}`}
          aria-describedby="field-tooltip"
        ></button>
      </Tooltip>
    );

  const buildTable = (colums, data) => {
    return (
      <table className="survey-table">
        <tr>
          <th></th>
          {data.map((d) => (
            <th>{d.components[0].label}</th>
          ))}
        </tr>
        {colums?.map((c) => (
          <tr>
            <td>{c}</td>
            {data.map((d) => (
              <td>{getFields(d.components[0], true, c)}</td>
            ))}
          </tr>
        ))}
      </table>
    );
  };
  const onTabChange = (i) => {
    setactiveIndex(i);
  };

  function isISODateString(str) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/.test(str);
  }

  const fileHandler = async (file, key) => {
    try {
      const uploadedUrl = await uploadBlob(file.originFile); // Use `originFile` to access the raw file
      const now = new Date();
      const formattedDate = now.toISOString().split("T")[0]; // Get the date in YYYY-MM-DD format
      const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // Get the time in HH-MM-SS format
      const fileId = `${file.name}_file_${formattedDate}_${time}`;

      setData((prevState) => ({
        ...prevState,
        [key]: {
          url: uploadedUrl,
          name: fileId,
        },
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const getFields = (question, table, c) => {
    if (
      !question.customConditional ||
      eval(
        question.customConditional
          ?.substring(question.customConditional?.indexOf("=") + 1)
          .trim()
          ?.replace(/\.includes/g, "?.includes")
          ?.replace(/\)data/g, ") && data")
      )
    ) {
      const prefilledValue = data[question.key] || question.defaultValue;
      if (question.type === "textfield") {
        return (
          <>
            <TextArea
              title={!table && question.label}
              defaultValue={prefilledValue}
              required={question.validate.required}
              maxLength={question.attributes.maxlength}
              name={
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              }
              onChange={handleChange}
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
            {errors.includes(
              table ? `${question.key}_${c?.replace(/\s/g, "")}` : question.key
            ) && (
              <div
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a value
              </div>
            )}
          </>
        );
      } else if (question.type === "select") {
        return (
          <>
            <Select
              data={question.data.values}
              required={question.validate.required}
              showSelectAll={true}
              multiple={question.multiple}
              placeholder={!table && question.label}
              name={question.key}
              onSelect={(value) =>
                setData((prevState) => ({
                  ...prevState,
                  [table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key]: value,
                }))
              }
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              error={errors.includes(
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              )}
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
            {errors.includes(
              table ? `${question.key}_${c?.replace(/\s/g, "")}` : question.key
            ) && (
              <div
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please select an option
              </div>
            )}
          </>
        );
      } else if (question.type === "datetime") {
        return (
          <>
            <CalendarPicker
              fieldTitle={!table && question.label}
              defaultValue={prefilledValue}
              required={question.validate.required}
              fieldWidth="100%"
              format={"DD/MM/YYYY"}
              placeholder="DD/MM/YYYY"
              editable={false}
              disabledDays={[].concat(
                question.datePicker.disableWeekends && [5, 6],
                question.datePicker.disableWeekdays && [0, 1, 2, 3, 4]
              )}
              onChange={(date) =>
                setData((prevState) => ({
                  ...prevState,
                  [table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key]: date,
                }))
              }
              value={
                data
                  ? (table
                      ? typeof data[`${question.key}_${c?.replace(/\s/g, "")}`] === 'string'
                        ? isISODateString(data[`${question.key}_${c?.replace(/\s/g, "")}`])
                          ? new Date(data[`${question.key}_${c?.replace(/\s/g, "")}`])
                          : JSON.parse(data[`${question.key}_${c?.replace(/\s/g, "")}`])
                        : data[`${question.key}_${c?.replace(/\s/g, "")}`]
                      : typeof data[question.key] === 'string'
                        ? isISODateString(data[question.key])
                          ? new Date(data[question.key])
                          : JSON.parse(data[question.key])
                        : data[question.key]
                    )
                  : undefined
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              useCustomValidation
              error={errors.includes(
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              )}
              customErrorNode={
                errors.includes(
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ) && (
                  <div
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a date
                  </div>
                )
              }
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
            {errors.includes(
              table ? `${question.key}_${c?.replace(/\s/g, "")}` : question.key
            ) && (
              <div
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a date
              </div>
            )}
          </>
        );
      } else if (question.type === "number") {
        return (
          <>
            <InputNumber
              title={!table && question.label}
              defaultValue={prefilledValue}
              required={question.validate.required}
              name={
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              }
              onChange={handleNumberChange}
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              error={errors.includes(
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              )}
              errorNode={
                errors.includes(
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ) && (
                  <div
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a number
                  </div>
                )
              }
              onInput={(e) =>
                (e.target.value = e.target.value.slice(
                  0,
                  question.attributes.maxlength
                ))
              }
              increaseIconClassName="inputNumberArrow"
              decreaseIconClassName="inputNumberArrow"
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
          </>
        );
      } else if (question.type === "file") {
        const fieldKey = table
          ? `${question.key}_${c?.replace(/\s/g, "")}`
          : question.key;
      
        const fileValue = data[fieldKey];
        const fileName = fileValue?.name;
        const fileUrl = fileValue?.url;
      
        return (
          <>
            <Upload
              title={!table && question.label}
              required={question.validate.required}
              multiple={false}
              autoUpload={false}
              acceptFileType=".pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX,.csv,.CSV,.ppt,.PPT,.pptx,.PPTX,.txt,.TXT,.rtf,.RTF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.zip,.ZIP,.rar,.RAR,.json,.JSON,.xml,.XML,.html,.HTML,.htm,.HTM,.md,.MD"
              uploadInstruction={question.tooltip}
              maxFileSize={10 * 1024 * 1024}
              config={{
                trigger: false,
                type: "inline",
                size: true,
              }}
              onChange={(file) => fileHandler(file, fieldKey)}
              name={fieldKey}
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              error={errors.includes(fieldKey)}
            />
            {question.tooltip && allTooltip(question.tooltip, question.customClass)}
            {errors.includes(fieldKey) && (
              <div aria-live="polite" className="ap-field-email-validation-error">
                Please upload a file
              </div>
            )}
            {previousSurveyID && fileName && (
              <p>
                Previously Uploaded File:{" "}
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                  {fileName}
                </a>
              </p>
            )}
          </>
        );
      } else if (question.type === "memo") {
        return <p>{question.label?.replace(/^[0-9]+/g, "")}</p>;
      } else if (question.type === "datagrid") {
        return (
          <>
            <p>
              {question.label}

              {question.tooltip !== "" && (
                <Tooltip
                  trigger="hover"
                  position="top-left"
                  distance={4}
                  appendAfterTarget={true}
                  content={question.tooltip}
                >
                  <button
                    data-tooltip="true"
                    tabIndex={0}
                    aria-label="tooltip"
                    className="Appkit4-icon icon-information-outline ap-field-icon-btn"
                    aria-describedby="field-tooltip"
                  ></button>
                </Tooltip>
              )}
            </p>
            {buildTable(
              question.defaultValue[0],
              question.components[0].rows[0]
            )}
          </>
        );
      } else {
        return <p>There is a problem displaying this question</p>;
      }
    }
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      {startSurvey ? (
        <>
          <Button
            onClick={() => {
              setStartSurvey(false);
            }}
            kind="text"
            className="back-button"
          >
            Back
          </Button>

          <Panel title={resSurveyName && resSurveyName}>
            {!isSubmitted && isActive && !isExpired ? (
              <>
                <Tabs
                  type="underline"
                  activeIndex={activeIndex}
                  onTabChange={onTabChange}
                  className="mt-md-3"
                  responsive
                >
                  {questinnaireJSON.map((component) => (
                    <Tab label={component.label} value={component.value}>
                      {component.components.map((question) =>
                        getFields(question)
                      )}
                    </Tab>
                  ))}
                </Tabs>

                <div className="row">
                  <div className="col-1">
                    <Button
                      kind="text"
                      icon="icon-left-chevron-outline"
                      onClick={() => setactiveIndex(activeIndex - 1)}
                      disabled={activeIndex === 0}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="col-5">
                    <Button
                      kind="text"
                      icon="icon-right-chevron-outline"
                      onClick={() => setactiveIndex(activeIndex + 1)}
                      disabled={activeIndex === questinnaireJSON.length - 1}
                    >
                      Next
                    </Button>
                  </div>

                  <div className="col-5">
                    <Button
                      kind="secondary"
                      icon="icon-save-outline"
                      className="right"
                      onClick={() => submitForm("save")}
                    >
                      Save as draft
                    </Button>
                  </div>
                  <div className="col-1">
                    <Button
                      kind="primary"
                      icon="icon-circle-checkmark-outline"
                      className="right"
                      onClick={() => setShowSubmitModal(true)}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            ) : isSubmitted ? (
              <p>This form has already been submitted</p>
            ) : !isActive || isExpired ? (
              <p>This form is no longer available</p>
            ) : (
              <p>Something went wrong</p>
            )}
          </Panel>
        </>
      ) : (
        <Panel title={resSurveyName && resSurveyName}>
          <div className="row">
            <div className="col-6">
              <div>
                {surveyDescription &&
                  surveyDescription.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
              </div>

              <p>
                Before getting started please ensure you have read our{" "}
                <Link
                  to="/privacy-statement"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ap-link"
                >
                  Privacy Statement
                </Link>
                ,{" "}
                <Link
                  to="/cookies-statement"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ap-link"
                >
                  Cookies Statement
                </Link>{" "}
                and{" "}
                <Link
                  to="/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ap-link"
                >
                  Terms of Use
                </Link>
                .
              </p>

              <p className="mb-md-3">
                <Radio
                  checked={acceptPrivacy}
                  onClick={() => setAcceptPrivacy(true)}
                >
                  I have read and accepted the aforementioned Privacy Statement,
                  Cookies Statement and Terms of Use.
                </Radio>
              </p>

              <p>
                Please note that responses to all these questions may not be
                required, as this questionnaire is logic based and depends on
                the options that you select as you work through the
                questionnaire. Your questionnaire can be saved for you to return
                later until it is submitted.
              </p>

              <Button
                kind="primary"
                disabled={!acceptPrivacy}
                icon="icon-arrow-right-outline"
                onClick={() => setStartSurvey(true)}
              >
                Begin Survey
              </Button>
            </div>

            <div className="col-6">
              <img src={surveypage} alt="survey page" />
            </div>
          </div>
        </Panel>
      )}
      <Modal
          visible={showSubmitModal}
          title="Support"
          onCancel={() => {
            setShowSubmitModal(false);
          }}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={<Badge type="warning" value="Warning"></Badge>}
          footer={
            <Button
              onClick={() => submitForm("submit")}
              loading={submitLoading}
              kind="primary"
              icon="icon-circle-checkmark-outline"
            >
              Submit
            </Button>
          }
          bodyStyle={{ minHeight: "92px" }}
        >
            <p>
              Are you sure to want to submit? Once you submit, you can not go back.
            </p>
        </Modal>
    </div>
  );
}

export default SurveyForm;
