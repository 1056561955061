import React, { useState, useEffect } from "react";
import { getrequest } from "../../services/global.service";
import { Loading, Panel, Button } from "@appkit4/react-components";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const ShowAllResponses = () => {
  const { surveyId } = useParams();
  const [responses, setResponses] = useState([]);
  const [fileLinks, setFileLinks] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [visibleResponses, setVisibleResponses] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (surveyId) {
      fetchResponses();
    } else {
      console.error("No survey ID provided in the URL");
      navigate("/service-problem");
    }
  }, [surveyId]);

  const fetchResponses = async () => {
    try {
      const teamName = JSON.parse(Cookies.get("seletedTeam")).name;
      const getSurveyDetailsURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/DownloadSurveyResponse?surveyId=`;

      // Fetch the survey responses as JSON data
      const response = await getrequest(
        `${getSurveyDetailsURL}${surveyId}&teamName=${teamName}`,
        {
          responseType: "json",
        }
      );

      let rawData = response.data;

      if (!rawData || !rawData.Responses || rawData.Responses.length === 0) {
        console.warn("No survey responses found.");
        setResponses([]);
        setIsLoading(false);
        return;
      }

      // const links = {};
      const normalizedResponses = rawData.Responses.map((item) => {
        const parsedResponse = JSON.parse(item.Response);
        const links = {};
        Object.keys(parsedResponse).forEach((key) => {
          if (typeof parsedResponse[key] === 'object' && parsedResponse[key] !== null && parsedResponse[key].url) {
            links[key] = parsedResponse[key].url;
            parsedResponse[key] = parsedResponse[key].name;
          } else if (typeof parsedResponse[key] === 'object' && parsedResponse[key] !== null) {
            parsedResponse[key] = JSON.stringify(parsedResponse[key]);
          }
        });
        return {
          ...item,
          ...parsedResponse,
          fileLinks: links,
        };
      });

      setResponses(normalizedResponses);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Bad Request:", error.response.data);
      } else {
        console.error("Error fetching survey responses:", error);
      }
      navigate("/service-problem");
    }
  };

  const toggleVisibility = (email) => {
    setVisibleResponses((prevState) => ({
      ...prevState,
      [email]: !prevState[email],
    }));
  };

  if (isLoading) {
    return (
      <div className="ap-container">
        <Button onClick={() => navigate('/survey-list')} kind="text" className="back-button">
          Back to Survey List
        </Button>
        <Loading
          loadingType="linear"
          indeterminate={true}
          compact={false}
          className="page-loader"
        ></Loading>
      </div>
    );
  }

  if (responses.length === 0) {
    return (
      <div className="ap-container">
        <Button onClick={() => navigate('/surveylist')} kind="text" className="back-button">
          Back to Survey List
        </Button>
        <Panel title="All Survey Responses">
          <p>No responses available.</p>
        </Panel>
      </div>
    );
  }

  const surveyFields = Object.keys(responses[0] || {}).filter(
    (field) => field !== "Email" && field !== "Response"
  );

  const formatHeader = (field) => {
    return field
      .replace(/_[a-zA-Z0-9]+$/g, '')
      .replace(/UUID\d+$/, '')
      .replace(/_/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      .replace(/\s+/g, ' ')
      .trim();
  };


  return (
    <div className="ap-container">
      <Button onClick={() => navigate("/surveylist")} kind="text" className="back-button">
        Back to Survey List
      </Button>
      <Panel title="All Survey Responses">
        <div style={{ overflowX: "auto" }}>
          <table className="ap-table ap-table-striped" style={{ minWidth: "1000px" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Email</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response, index) => (
                <React.Fragment key={index}>
                  {/* Email and Button Row */}
                  <tr
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                    }}
                  >
                    <td
                      colSpan={2} // Merge the columns to ensure the row spans the entire width
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "15px 10px",
                      }}
                    >
                      <span style={{ textAlign: "left" }}>{response.UserEmail}</span>
                      <Button
                        onClick={() => toggleVisibility(response.UserEmail)}
                        kind="primary"
                      >
                        {visibleResponses[response.UserEmail] ? "Hide Responses" : "Show Responses"}
                      </Button>
                    </td>
                  </tr>

                  {/* Collapsible Row for Responses */}
                  {visibleResponses[response.UserEmail] && (
                    <tr>
                      <td colSpan={2}>
                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            border: "1px solid #ddd",
                            padding: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <table
                            className="ap-table ap-table-bordered"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            <thead>
                              <tr>
                                <th style={{ width: "50%", textAlign: "centre" }}>Question</th>
                                <th style={{ width: "50%", textAlign: "centre" }}>Answer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {surveyFields.map((field) => {
                                const value = response[field];
                                const links = response.fileLinks || {};
                                // Skip fields where the value is an object
                                if (value && typeof value === "object") {
                                  return null;
                                }
                                return (
                                  <tr key={field}>
                                    <td>{formatHeader(field)}</td>
                                    <td>
                                      {links[field] && value && value.includes("_file") ? (
                                        <div>
                                          <span>{value}</span>
                                          <Button
                                            kind="text"
                                            icon="icon-download-outline"
                                            onClick={() =>
                                              window.open(links[field], "_blank")
                                            }
                                          >
                                            Download File
                                          </Button>
                                        </div>
                                      ) : (
                                        value != null ? value.toString() : "-"
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </Panel>
    </div>
  );
  };

export default ShowAllResponses;



